import React, { useState } from "react";
import { graphql } from "gatsby";
import * as _ from "lodash";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Tombstone from "../components/tombstone";
import Filters from "../components/services/filters";
import Pagination from "../components/pagination";
import { getYears } from "../utils/filters";

export default function SelectedTransactions({
  data: { tombstones, page, types, regions, sectors },
  pageContext,
}) {
  /**
   * state
   */
  const [yearIDs, setYearIDs] = useState([]);
  const [typeIDs, setTypeIDs] = useState([]);
  const [sectorIDs, setSectorIDs] = useState([]);
  const [regionIDs, setRegionIDs] = useState([]);

  /**
   * variables
   */
  const years = getYears(tombstones.nodes);
  const options = {
    years: years,
    regions: regions.nodes,
    types: types.nodes,
    sectors: sectors.nodes,
  };
  const filters = { setYearIDs, setTypeIDs, setSectorIDs, setRegionIDs };

  // https://betterprogramming.pub/advanced-data-filtering-in-react-5ea2fa3befca
  /**
   * functions
   */

  const isShownByYear = (node) => {
    if (!yearIDs.length) return true;
    return yearIDs.some((id) => id === node.tombstone.year);
  };

  const isShownByType = (node) => {
    if (!typeIDs.length) return true;
    if (!node.tombstone.transactionType) return false;
    return typeIDs.some(
      (id) => id === node.tombstone.transactionType[0].databaseId
    );
  };

  const isShownBySector = (node) => {
    if (!sectorIDs.length) return true;
    if (!node.tombstone.sector) return false;
    return sectorIDs.some((id) => id === node.tombstone.sector[0].databaseId);
  };

  const isShownByRegion = (node) => {
    if (!regionIDs.length) return true;
    if (!node.tombstone.region) return false;
    return regionIDs.some((id) => id === node.tombstone.region[0].databaseId);
  };

  const applyFilters = (tombstones) => {
    return tombstones.filter((node) => {
      const showByYear = isShownByYear(node);
      const showByType = isShownByType(node);
      const showBySector = isShownBySector(node);
      const showByRegion = isShownByRegion(node);
      return showByYear && showByType && showBySector && showByRegion;
    });
  };

  const filteredTombstones = applyFilters(tombstones.nodes);

  return (
    <Layout>
      <Seo seo={page.seo} />

      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">{page.title}</h3>
      </div>

      {page.content && (
        <div className="wysiwyg__content__wrapper pt-6 pb-20">
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      )}

      <div className="tombstone-list__wrapper">
        <Filters {...{ options }} {...{ filters }} />
      </div>

      <div className="tombstone-list__wrapper pb-6">
        {filteredTombstones.map(({ tombstone }, index) => (
          <Tombstone key={index} {...{ tombstone }} />
        ))}
      </div>
      <div className="tombstone-list__wrapper pb-6">
        <Pagination {...{ pageContext }} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    page: wpPage(slug: { eq: "selected-transactions" }) {
      content
      title
      seo {
        fullHead
      }
    }
    tombstones: allWpTombstone(
      sort: { fields: tombstone___year, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        tombstone {
          title
          clientRequirement
          dealOutcome
          description
          icRole
          logo {
            sourceUrl
          }
          transactionSummary
          year
          transactionType {
            name
            databaseId
          }
          sector {
            name
            databaseId
          }
          region {
            name
            databaseId
          }
        }
        uri
      }
    }
    types: allWpTransactionType {
      nodes {
        name
        databaseId
      }
    }
    regions: allWpTransactionRegion {
      nodes {
        name
        databaseId
      }
    }
    sectors: allWpTransactionSector {
      nodes {
        name
        databaseId
      }
    }
  }
`;
