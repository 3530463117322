import React from "react";
import Select from "react-select";

export default function Filters({ options, filters }) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #979ca24f",
      color: state.isSelected ? "#e87200" : "#002856",
      padding: "1rem",
      backgroundColor: state.isSelected ? "#e1e1e172" : "white",
    }),
    control: (provided) => ({
      ...provided,
      border: "solid 1px #979ca2",
      width: "26rem",
      "@media (max-width: 767px)": {
        ...provided["@media (max-width: 767px)"],
        width: "32.6rem",
        fontSize: "2.6rem",
        marginBottom: "1.5rem",
      },
    }),
  };

  return (
    <div className="filters">
      <Select
        isMulti={true}
        styles={customStyles}
        options={options.years}
        hideSelectedOptions={false}
        placeholder="Filter by year"
        onChange={(option) => {
          filters.setYearIDs(option?.map((o) => o.value || []));
        }}
      />
      <Select
        isMulti={true}
        styles={customStyles}
        options={options.types}
        hideSelectedOptions={false}
        placeholder="Filter by type"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.databaseId}
        onChange={(option) => {
          filters.setTypeIDs(option?.map((o) => o.databaseId || []));
        }}
      />
      <Select
        isMulti={true}
        styles={customStyles}
        options={options.sectors}
        hideSelectedOptions={false}
        placeholder="Filter by sector"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.databaseId}
        onChange={(option) => {
          filters.setSectorIDs(option?.map((o) => o.databaseId || []));
        }}
      />
      <Select
        isMulti={true}
        styles={customStyles}
        options={options.regions}
        hideSelectedOptions={false}
        placeholder="Filter by region"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.databaseId}
        onChange={(option) => {
          filters.setRegionIDs(option?.map((o) => o.databaseId || []));
        }}
      />
    </div>
  );
}
