import React from "react";

import logo from "../images/logo.png";

export default function Tombstone({ tombstone }) {
  return (
    <div className="tombstone">
      <p className="tombstone__year">{tombstone.year}</p>
      <img className="tombstone__img" src={tombstone?.logo?.sourceUrl} alt="" />

      <div className="tombstone__text">
        <p className="tombstone__title">{tombstone?.title}</p>
        <p className="tombstone__description">{tombstone?.description}</p>
      </div>
      <img className="tombstone__ic-logo " src={logo} alt="" />
    </div>
  );
}
